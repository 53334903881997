<template>
  <v-slide-group
    multiple
    show-arrows
  >
    <template v-for="(date, index) in dates">
      <HorizontalDateItem
        :date="date"
        :key="index"
      />
    </template>
  </v-slide-group>
</template>

<script>

import HorizontalDateItem from './HorizontalDateItem'

export default {
    name: 'HorizontalDateSelector',
    components: {
        HorizontalDateItem
    },
    data() {
        return {
            millisInOneDay: 86400000,
            endTimeInMillis: Date.now(),
            daysBack: 60,
            selectedDate: new Date()
        }
    },
    computed: {
        startTimeInMillis() {
            return this.endTimeInMillis - (this.millisInOneDay * this.daysBack)
        },
        dates() {
            const dates = []
            const date = new Date()
            date.setTime(this.startTimeInMillis)

            while(date.getTime() < this.endTimeInMillis) {
                const dateString = date.toISOString()
                dates.push(dateString)
                const newTime = date.getTime() + this.millisInOneDay
                date.setTime(newTime)
            }

            return dates
        }
    },
    created() {

    }
}
</script>